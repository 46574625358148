<script setup lang="ts">
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import { type PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Category } from '@/Types/Models/Category'

const props = defineProps({
    category: {
        type: Object as PropType<Category>,
        required: true
    },
    size: {
        type: String,
        default: 'small'
    },
    minimal: {
        type: Boolean,
        default: false
    }
})

const textColour = `color: ${props.category.metadata?.colour}`
// const bgColour = props.category ? `background-color:${useModifyColour(props.category.metadata?.colour ?? 'var(--colors-primary)', 200)};` : null
const bgColour = props.category ? `background-color:${props.category.metadata?.colour ?? 'var(--colors-primary)'};` : null
const icon = CategoryIconMap[props.category.title]
</script>

<template>
    <div>
        <Link
            v-if="category"
            :href="useRoute('articles.categorised', category)"
            class="z-10 font-semibold text-white transition-colors group/category">
            <div
                :class="{
                    'text-xs': size === 'small',
                    'text-sm': size !== 'small',
                    'py-1 px-2.5 rounded-full': size !== 'small' && !minimal,
                    'py-0.5 px-2 rounded-md': size === 'small' && !minimal,
                    'w-auto inline-flex': minimal
                }"
                :style="[
                    minimal ? textColour : bgColour,
                ]"
                class="flex select-none items-center dark:!text-white transition-colors space-x-1">
                <component
                    :is="icon"
                    v-if="icon && size !== 'small'"
                    :alt="`Icon for ${category.singular_title}`"
                    class="flex size-3.5" />
                {{ category.singular_title }}
            </div>
        </Link>
        <div
            v-else
            class="z-10 rounded bg-white font-semibold transition-colors group/category">
            <div
                :class="[size === 'small' ? 'text-xs' : 'text-sm']"
                class="flex select-none items-center rounded px-2 py-1 transition-colors space-x-1 bg-secondary/20">
                <span>Uncategorised</span>
            </div>
        </div>
    </div>
</template>
