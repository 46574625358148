<script setup lang="ts">

import Dropdown from '@/Components/UI/Dropdown.vue'
import Horizon from '@/Components/Icon/Horizon.vue'
import Dashboard from '@/Components/Icon/Dashboard.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { UserPermission } from '@/Enums/UserPermission.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { ArrowUpRightIcon } from '@heroicons/vue/20/solid'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'
import { usePermissions } from '@/Composables/usePermissions.js'
import { useCurrentUser } from '@/Composables/useCurrentUser.js'
import {
    ArrowLeftEndOnRectangleIcon,
    BookmarkIcon,
    Cog6ToothIcon,
    AdjustmentsHorizontalIcon,
    DocumentTextIcon,
    PlusIcon,
    ChartBarIcon
} from '@heroicons/vue/24/outline'

function items () {
    return [
        {
            header: usePageProps().auth.user.name,
            title: 'View profile',
            url: useRoute('user.show', { user: usePageProps().auth.user }),
            permission: useIsAuthed(),
            icon: ArrowUpRightIcon
        },
        {
            url: null,
            title: 'sep'
        },
        {
            title: 'Admin',
            url: '/admin',
            icon: Cog6ToothIcon,
            target: '_blank',
            permission: usePermissions(UserPermission.VIEW_ADMIN_PANEL)
        },
        {
            title: 'Horizon',
            url: '/horizon',
            icon: Horizon,
            target: '_blank',
            permission: usePermissions(UserPermission.VIEW_HORIZON_PANEL)
        },
        {
            title: 'Analytics',
            url: useRoute('analytics'),
            icon: ChartBarIcon,
            target: '_blank',
            permission: usePermissions(UserPermission.VIEW_ANALYTICS)
        },
        {
            url: null,
            title: 'sep',
            if: usePermissions(UserPermission.VIEW_ADMIN_PANEL)
        },
        {
            title: 'Dashboard',
            url: useRoute('dashboard.home'),
            target: '_blank',
            icon: Dashboard,
            permission: usePermissions(UserPermission.VIEW_DASHBOARD)
        },
        {
            title: 'My articles',
            url: useRoute('dashboard.articles'),
            icon: DocumentTextIcon,
            permission: usePermissions(UserPermission.CREATE_ARTICLE)
        },
        {
            title: 'New article',
            url: useRoute('dashboard.article.create'),
            icon: PlusIcon,
            permission: usePermissions(UserPermission.CREATE_ARTICLE)
        },
        {
            title: 'Bookmarks',
            url: useRoute('articles.bookmarked', { status: 'unread' }),
            icon: BookmarkIcon,
            permission: usePermissions(UserPermission.BOOKMARK_ARTICLES)
        },
        {
            title: 'Settings',
            url: useRoute('dashboard.user.settings'),
            icon: AdjustmentsHorizontalIcon
        },
        {
            url: null,
            title: 'sep'
        },
        {
            title: 'Log out',
            url: useRoute('logout.delete', { redirect: useCurrentUrl() }),
            method: 'DELETE',
            component: Link,
            icon: ArrowLeftEndOnRectangleIcon,
            permission: useIsAuthed()
        }
    ]
}
</script>

<template>
    <Dropdown
        :items="items()"
        shortcut="p">
        <template #toggle>
            <UserAvatar
                :user="useCurrentUser()"
                class="aspect-square w-10 rounded-full ring-1 ring-zinc-800/10"
                size="xsmall" />
        </template>
    </Dropdown>
</template>
