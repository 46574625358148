<script setup lang="ts">
import bell from '~/app/notifications/bell.png'
import patternImage from '~/app/patterns/logo.svg'
import { computed } from 'vue'
import type { Toast } from '@/Types/Toast'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    toast: {
        type: Object as () => Toast,
        required: true
    }
})

const emit = defineEmits(['close'])

function close () {
    emit('close')
}

const hasImage = computed(() => {
    return !!props.toast.options?.image
})

const hasDescription = computed(() => {
    return !!props.toast.options?.description
})

const hasLink = computed(() => {
    return !!props.toast.options?.url
})
</script>

<template>
    <div
        class="pointer-events-auto relative flex w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl ring-1 ring-zinc-400/10 shadow-primary-light/10 dark:bg-zinc-950 dark:shadow-zinc-950/20">
        <component
            :is="hasLink ? 'a' : 'div'"
            :href="hasLink ? props.toast.options?.url : null"
            class="w-0 flex-1 px-3"
            :class="!hasImage ? 'py-3' : 'py-3'">
            <div class="flex items-center">
                <div
                    class="shrink-0 pt-0.5">
                    <div
                        v-if="hasImage"
                        v-lazy:background="patternImage"
                        class="flex aspect-square h-16 items-center justify-center overflow-hidden rounded-lg bg-center bg-repeat ring-1 ring-zinc-400/10 bg-[length:30px]"
                    >
                        <img
                            v-if="hasImage"
                            class="object-cover object-center"
                            :class="hasImage ? 'w-full h-full' : 'w-12 h-12'"
                            :src="toast.options?.image"
                            alt="">
                    </div>
                    <div
                        v-else
                        :class="['error', 'fail'].includes(toast.level) ? 'bg-red-400 w-10 ml-1' : 'bg-background  w-12'"
                        class="flex aspect-square items-center justify-center rounded-full dark:bg-zinc-800">
                        <div
                            v-if="toast.level === 'success'">
                            <img
                                class="h-8 w-8"
                                :src="bell"
                                alt="Notification bell icon">
                        </div>
                        <XMarkIcon
                            v-if="['error', 'fail'].includes(toast.level)"
                            class="w-5 text-white" />
                    </div>
                </div>

                <div
                    class="ml-3 w-0 flex-1"
                    :class="hasImage ? 'ml-3' : ''">
                    <p
                        class="select-none text-sm text-zinc-900 dark:text-white"
                        :class="hasDescription ? 'font-semibold' : ''">
                        {{ props.toast.title }}
                    </p>
                    <p
                        v-if="hasDescription"
                        class="select-none text-sm text-zinc-900/75 line-clamp-2"
                        v-html="toast.options?.description" />
                </div>
            </div>
        </component>
        <div class="flex">
            <button
                type="button"
                class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent px-4 text-sm font-medium text-zinc-900/30 hover:text-zinc-900 focus:outline-none focus:ring-0"
                @click="close">
                <XMarkIcon class="w-5" />
            </button>
        </div>
    </div>
</template>
