<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

defineProps({
    variant: {
        type: String,
        default: 'primary'
    },
    padding: {
        type: String,
        default: 'px-4 py-2'
    },
    append: [Object, null],
    textDesktopOnly: {
        type: Boolean,
        default: false
    },
    text: {
        type: String,
        default: 'Write a new article'
    }
})

function handleClick () {
    useAnalyticsEvent('Click from ArticleCreateButton')
    if (!useIsAuthed()) {
        useEmitter.emit('openAuthModal')
    }
}
</script>

<template>
    <div class="flex">
        <Button
            :variant="variant"
            class="flex"
            :href="useIsAuthed() ? useRoute('dashboard.article.create', append) : null"
            :icon="PencilSquareIcon"
            icon-first
            :icon-only="textDesktopOnly ? 'mobile' : false"
            @click="handleClick()">
            <span
                class="shrink-0"
                :class="textDesktopOnly ? 'hidden md:flex' : ''">{{ text }}</span>
        </Button>
    </div>
</template>
