<script setup>
import Discord from '@/Components/Icon/Discord.vue'
import Facebook from '@/Components/Icon/Facebook.vue'
import TwitterX from '@/Components/Icon/TwitterX.vue'
import Instagram from '@/Components/Icon/Instagram.vue'
import NavigationMobileNavItem from '@/Components/Navigation/NavigationMobileNavItem.vue'
import { router } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'
import { usePageProps } from '@/Composables/usePageProps.ts'

const props = defineProps({
    menu: Array,
    open: {
        type: Boolean,
        default: false
    }
})

const navOpen = ref(props.open)
const displayNavItems = ref(props.open)

const emit = defineEmits(['close'])

function openNav () {
    navOpen.value = true
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
        displayNavItems.value = true
    }, 150)
}

function toggleNav () {
    navOpen.value === true ? closeNav() : openNav()
}

function closeNav () {
    if (navOpen.value) {
        navOpen.value = false
        document.body.style.overflow = ''
        displayNavItems.value = false
        emit('close')
    }
}

const notEmpty = (value) => {
    return value !== undefined && value !== null && value !== ''
}

function show (item) {
    let canShow = (notEmpty(item.if) ? item.if : true)

    if (typeof item.permission !== 'undefined') {
        canShow = item.permission === true
    }

    return canShow
}

watch(() => props.open, toggleNav)

onMounted(() => {
    router.on('navigate', closeNav)
})
</script>

<template>
    <div :class="navOpen ? 'pointer-events-auto' : 'pointer-events-none'">
        <div
            :class="navOpen ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'"
            class="absolute left-0 top-14 z-10 h-[calc(100dvh-3.5rem)] w-10/12 bg-white dark:bg-zinc-900 transition-all duration-300 ease-out">
            <div class="relative z-10 flex h-full w-full flex-col overflow-y-auto p-2 pb-4">
                <transition-group
                    class="flex flex-col space-y-2"
                    name="slide-fade"
                    tag="ul">
                    <template v-for="(item, i) in menu">
                        <li
                            v-if="displayNavItems && (typeof item.permission === 'undefined' || item.permission === true) && show(item)"
                            :key="i"
                            :style="{'--i': i}">
                            <NavigationMobileNavItem
                                :item="item"
                                @clicked="closeNav" />
                        </li>
                    </template>
                </transition-group>
                <div class="mt-auto -mx-2">
                    <div class="border-t px-4 pt-4 mt-4 border-zinc-100 dark:border-zinc-700">
                        <div class="flex items-center text-zinc-900 space-x-8 dark:text-white">
                            hi
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
