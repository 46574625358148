<script setup>
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { cn } from '@/utils.ts'
import { onMounted, ref } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const emit = defineEmits(['clicked'])

const props = defineProps({
    item: Object
})

const open = ref(false)
const active = ref(false)

function markActive () {
    active.value = useCurrentUrl() === props.item.url ||
        (useCurrentUrl() + '/') === props.item.url ||
        useCurrentUrl() === props.item.url + '/'

    if (props.item.children?.length) {
        props.item.children.forEach((child) => {
            if (useCurrentUrl() === child.url) {
                active.value = true
                open.value = true
            }
        })
    }
}

function handleClick () {
    emit('clicked')
}

onMounted(() => {
    router.on('navigate', () => {
        markActive()
    })
    markActive()
})
</script>

<template>
    <li
        v-if="(typeof item.permission === 'undefined' || item.permission === true)"
        :class="[
            cn(
                'text-sm  dark:text-white  rounded-lg p-2.5 px-2.5 border border-transparent',
                active ? 'bg-primary/5 dark:bg-white/10 text-primary' : 'bg-transparent text-zinc-700',
                item.classes,
            )
        ]">
        <component
            :is="item.url ? Link : 'button'"
            :href="item.url"
            class="flex flex-col w-full"
            @click="item.url ? handleClick() : open = !open">
            <div class="flex items-center w-full justify-between">
                <div class="flex items-center">
                    <component
                        :is="item.icon"
                        v-if="item.icon && ['object', 'function'].includes(typeof item.icon)"
                        class="mr-2 w-5" />
                    {{ item.title }}
                </div>
                <ChevronDownIcon
                    v-if="item.children"
                    :class="open ? 'rotate-180' : 'rotate-0'"
                    class="w-5 transition-all" />
            </div>
        </component>
        <AccordionTransition>
            <div
                v-if="open && item.children"
                class="flex flex-col pl-8 space-y-4 pb-2 mt-4">
                <template v-for="(child, i) in item.children">
                    <li
                        v-if="typeof child.permission === 'undefined' || child.permission === true"
                        :key="i"
                        class="py-1"
                        :style="{'--i': i}">
                        <Link
                            :as="['delete', 'post', 'path'].includes(child.method) ? 'button' : 'a'"
                            :href="child.url"
                            :method="child.method"
                            :class="child.url === useCurrentUrl() ? 'font-semibold text-primary' : 'text-zinc-900'"
                            class="flex w-full items-center text-sm"
                            @click="handleClick">
                            <component
                                :is="child.icon"
                                v-if="child.icon && ['object', 'function'].includes(typeof child.icon)"
                                class="mr-2 w-5" />
                            {{ child.title }}
                            <component
                                :is="child.icon"
                                v-if="child.icon && typeof child.icon === 'object'"
                                class="ml-1 w-4" />
                        </Link>
                    </li>
                </template>
            </div>
        </AccordionTransition>
    </li>
</template>
