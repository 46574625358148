<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import InputDescription from '@/Components/Input/InputDescription.vue'
import { computed, getCurrentInstance, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: [Array, Boolean],
        default: false
    },
    value: { type: [Boolean, Object, String, Number] },
    disabled: { type: Boolean },
    label: {
        type: String,
        default: null
    },
    textFirst: {
        type: Boolean,
        default: false
    },
    description: {
        type: String,
        default: null
    },
    error: {
        type: String,
        default: null
    }
})

const proxyChecked = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})

const instance = getCurrentInstance()
const uid = ref(instance.uid)
</script>

<template>
    <div>
        <label
            :for="`toggle-${uid}`"
            :class="textFirst ? 'flex-row-reverse justify-between md:items-center' : ''"
            class="flex text-left group">
            <input
                :id="`toggle-${uid}`"
                v-model="proxyChecked"
                :value="value"
                :disabled="disabled"
                :class="[disabled ? 'bg-zinc-100 dark:bg-zinc-700' : 'bg-white dark:bg-zinc-800', textFirst ? '' : 'mt-1']"
                class="rounded border-zinc-300 dark:border-zinc-700 size-[1.125rem] ring-primary-light ml-0.5 text-primary checked:border-primary focus-visible:outline-none focus-visible:ring"
                type="checkbox">

            <span
                :class="textFirst ? 'pl-0' : 'pl-2.5'"
                class="flex flex-col">
                <InputLabel
                    v-if="label"
                    :is-label="false"
                    :class="[disabled ? 'cursor-default' : '', 'mt-[2px]']">
                    {{ label }}
                </InputLabel>
                <InputDescription
                    v-if="description"
                    :description="description" />
            </span>
        </label>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
