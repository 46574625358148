<script setup>
import UserCard from '@/Components/User/UserCard.vue'
import BasePalette from '@/Components/Global/BasePalette.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import ArticleCardHorizontal from '@/Components/Article/ArticleCardHorizontal.vue'
import { Link, router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useActiveElement, useMagicKeys } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['close', 'open', 'selected'])

const open = ref(props.show)
const input = ref('')
const index = ref(-1)

const keys = useMagicKeys({
    passive: true
})
const cmdK = keys['Meta+K']
const activeElement = useActiveElement()
const notUsingInput = computed(() =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA'
)

watch(cmdK, (value) => {
    if (value && notUsingInput.value) {
        open.value = true
        emit('open')
    }
})

function close () {
    emit('close')
    open.value = false
}

onMounted(() => {
    document.addEventListener('keydown', closeOnEscape)
})

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) {
        close()
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', closeOnEscape)
    document.body.style.overflow = null
})

router.on('navigate', close)

function groupResultsByType (results) {
    let index = 0
    return results.reduce((acc, result) => {
        if (!acc[result.type]) {
            acc[result.type] = []
        }

        acc[result.type].push({
            data: result.data,
            url: result.url,
            index
        })

        index++
        return acc
    }, {})
}

function selectResult (result) {
    if (!result) {
        return router.get(useRoute('search.index', { query: input.value }))
    }

    router.get(result.url)
}
</script>

<template>
    <BasePalette
        v-model:input="input"
        v-model:selected-index="index"
        search-route="api.search"
        :show="show"
        @close="close"
        @selected="selectResult">
        <template #results="{ results }">
            <div class="-mt-[1.15rem]">
                <div
                    v-for="(resultLinks, type) in groupResultsByType(results)"
                    :key="type">
                    <div class="w-full bg-zinc-100 dark:bg-zinc-800 dark:text-white py-1 text-sm font-semibold uppercase text-zinc-500 my-2.5 px-2.5">
                        {{ type }}
                    </div>
                    <div
                        v-for="(result, i) in resultLinks"
                        :key="i"
                        class="mx-2 cursor-pointer rounded-xl"
                        :class="[{ 'bg-primary/10 dark:bg-zinc-800': index === result.index }]"
                        @mouseover="index = result.index">
                        <Link :href="result.url">
                            <div class="pointer-events-none w-full p-2">
                                <ArticleCardHorizontal
                                    v-if="type === 'article'"
                                    :actions="false"
                                    :article="result.data" />
                                <UserCard
                                    v-if="type === 'user'"
                                    inline
                                    small
                                    :actions="false"
                                    :user="result.data" />
                                <GameCardHorizontal
                                    v-if="type === 'game'"
                                    inline
                                    small
                                    :actions="false"
                                    :game="result.data" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </template>
    </BasePalette>
</template>
