/* eslint-disable no-unused-vars */
export enum UserPermission {
VIEW_ADMIN_PANEL = "view admin panel",
VIEW_HORIZON_PANEL = "view horizon panel",
VIEW_TELESCOPE_PANEL = "view telescope panel",
VIEW_PULSE_PANEL = "view pulse panel",
VIEW_ANALYTICS = "view analytics",
VIEW_DASHBOARD = "view dashboard",
CREATE_ARTICLE = "create article",
UPDATE_ANY_ARTICLE = "update any article",
EDIT_ANY_ARTICLE = "edit any article",
DELETE_ANY_ARTICLE = "delete any article",
UNPUBLISH_ANY_ARTICLE = "unpublish any article",
UNPUBLISH_OWN_ARTICLE = "unpublish own article",
RESTORE_ANY_ARTICLE = "restore any article",
UPDATE_OWN_ARTICLE = "update own article",
EDIT_OWN_ARTICLE = "edit own article",
DELETE_OWN_ARTICLE = "delete own article",
RESTORE_OWN_ARTICLE = "restore own article",
MODERATE_ARTICLE = "moderate article",
INSTANTLY_PUBLISH_ARTICLE = "instantly publish article",
CREATE_COMMENT = "create comment",
UPDATE_ANY_COMMENT = "update any comment",
EDIT_ANY_COMMENT = "edit any comment",
DELETE_ANY_COMMENT = "delete any comment",
UPDATE_OWN_COMMENT = "update own comment",
EDIT_OWN_COMMENT = "edit own comment",
DELETE_OWN_COMMENT = "delete own comment",
DELETE_OWN_MODEL_COMMENT = "delete own model comment",
TOGGLE_OWN_ARTICLE_COMMENTS = "toggle own article comments",
TOGGLE_ANY_ARTICLE_COMMENTS = "toggle any article comments",
CREATE_POST = "create post",
UPDATE_ANY_POST = "update any post",
EDIT_ANY_POST = "edit any post",
DELETE_ANY_POST = "delete any post",
UPDATE_OWN_POST = "update own post",
EDIT_OWN_POST = "edit own post",
DELETE_OWN_POST = "delete own post",
RESTORE_OWN_POST = "restore own post",
RESTORE_ANY_POST = "restore any post",
CREATE_USER = "create user",
UPDATE_ANY_USER = "update any user",
EDIT_ANY_USER = "edit any user",
DELETE_ANY_USER = "delete any user",
UPDATE_OWN_USER = "update own user",
EDIT_OWN_USER = "edit own user",
DELETE_OWN_USER = "delete own user",
FOLLOW_USER = "follow user",
BE_FOLLOWED = "be followed",
FOLLOW_TAG = "follow tag",
FOLLOW_GAME = "follow game",
LIKE_COMMENT = "like comment",
LIKE_ARTICLE = "like article",
BOOKMARK_ARTICLES = "bookmark articles",
LIKE_GAME = "like game",
LIKE_POST = "like post"
}
/* eslint-disable no-unused-vars */
