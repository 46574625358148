<script lang="ts" setup>
import useEmitter from '@/Composables/useEmitter'
import type { PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'

const props = defineProps({
    article: Object as PropType<Article>
})

function formatDate (date: string) {
    return useDateFormat(date, 'DD MMM YYYY HH:mm', { locales: usePageProps().app.locale }).value
}

function timeagoDate (date: string) {
    let timeago = useTimeAgo(date).value

    timeago = timeago.replace('ago', '')
    timeago = timeago.replace('hour', 'hr')
    timeago = timeago.replace('minute', 'min')
    timeago = timeago.replace('second', 'sec')
    timeago = timeago.replace('day', 'd')
    timeago = timeago.replace('month', 'mth')
    timeago = timeago.replace('year', 'yr')

    return timeago.toLowerCase()
}

async function showModal () {
    return useMakeRequest(useRoute('api.article.updates', { article: props.article }), 'GET').then(response => {
        useEmitter.emit('article-updates-modal:show', {
            updates: response.updates
        })
    })
}
</script>

<template>
    <span
        v-tooltip="formatDate(article.updated_at)"
        class="text-inherit leading-[0]"
        itemprop="datePublished">
        {{ timeagoDate(article.updated_at) }}
    </span>
    <button
        v-if="article.hasBeenRepublished"
        v-tooltip="`Published: ${formatDate(article.date)} (${timeagoDate(article.date)})`"
        class="cursor-pointer"
        @click="showModal()">
        <span class="sr-only">Updated</span>
        <ArrowPathIcon class="h-4 w-4" />
    </button>
</template>
