<script setup lang="ts">
import Spinner from '@/Components/UI/Spinner.vue'
import { Link } from '@inertiajs/vue3'
import { type PropType, useSlots } from 'vue'

type ButtonVariant =
    'text'
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'danger'
    | 'danger-light'
    | 'white'
    | 'ghost';

const props = defineProps({
    target: {
        type: String,
        default: null
    },
    icon: {
        type: [Object, Function],
        default: null
    },
    href: {
        type: String,
        default: null
    },
    method: {
        type: String,
        default: 'get'
    },
    rounded: {
        type: Boolean,
        default: false
    },
    iconClasses: {
        type: String,
        default: ''
    },
    variant: {
        type: String as PropType<ButtonVariant>,
        default: 'primary'
    },
    loading: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    xSmall: {
        type: Boolean,
        default: false
    },
    iconOnly: {
        type: [Boolean, String],
        default: false
    },
    iconFirst: {
        type: Boolean,
        default: false
    },
    prefetch: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['click'])

let componentTag: string | typeof Link = 'button'
if (props.href) {
    componentTag = Link
}

let buttonColours = ''

switch (props.variant) {
case 'primary':
    buttonColours = 'button-primary'
    break
case 'primary-light':
    buttonColours = 'button-primary-light'
    break
case 'secondary':
    buttonColours = 'button-secondary'
    break
case 'danger':
    buttonColours = 'button-danger'
    break
case 'danger-light':
    buttonColours = 'button-danger-light'
    break
case 'white':
    buttonColours = 'button-white'
    break
case 'text':
    buttonColours = 'button-text'
    break
case 'ghost':
    buttonColours = 'button-ghost'
    break
default:
    buttonColours = ''
}

let defaultClasses = 'button'
defaultClasses += props.variant === 'white'
    ? ' hover:after:opacity-5'
    : ' hover:after:opacity-10'

const slot = useSlots()
const hasIcon = (props.icon !== null || slot.icon !== undefined) && slot.default !== undefined
const isIconOnly = props.iconOnly === true || ((props.icon !== null || slot.icon !== undefined) && (!slot.default))

const isNotText = props.variant !== 'text'

function handleClick (): void {
    if (!props.href) {
        emit('click')
    }
}
</script>

<template>
    <component
        :is="target !== null ? 'a' : componentTag"
        :prefetch="prefetch"
        :method="method"
        :type="componentTag === 'button' ? 'button' : null"
        :target="target"
        :class="[
            buttonColours,
            {
                'text-xs': xSmall,
                'small': small,
                'x-small': xSmall,

                'aspect-square': isIconOnly,

                'aspect-square md:aspect-[unset]': hasIcon && iconOnly === 'mobile',

                'px-5': !isIconOnly && !small && !xSmall && isNotText && iconOnly !== 'mobile',
                'px-3': !isIconOnly && small && !xSmall && iconOnly !== 'mobile' && variant !== 'text',
                'px-2.5': !isIconOnly && xSmall && iconOnly !== 'mobile',

                'md:px-5': !isIconOnly && !small && !xSmall && isNotText && iconOnly === 'mobile',
                'md:px-3': !isIconOnly && small && !xSmall && iconOnly === 'mobile',
                'md:px-1.5': !isIconOnly && xSmall && iconOnly === 'mobile',
            },
            (rounded || xSmall) ? 'rounded-full after:rounded-full' : 'rounded-lg after:rounded-[0.45rem]',
            defaultClasses,
            (loading || disabled) ? 'pointer-events-none opacity-50' : ''
        ]"
        :disabled="loading || disabled"
        :href="href"
        @click="handleClick">
        <span
            :class="!loading ? 'opacity-0 scale-0' : 'opacity-100 scale-100'"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
            <Spinner
                class="h-4 w-4 text-black/90"
                spinner="fill-white"
            />
        </span>
        <span
            :class="[
                loading ? 'scale-0 opacity-0' : 'scale-100 opacity-100',
                iconFirst ? 'flex-row-reverse' : '',
                hasIcon && iconOnly === 'mobile' ? 'gap-0 md:gap-2' : 'gap-2',
                iconOnly === 'mobile' ? 'w-full md:auto' : '',
                hasIcon && !iconOnly && iconFirst ? 'mr-1' : ''
            ]"
            class="flex h-full items-center justify-center text-center">
            <template
                v-if="slot.default && !isIconOnly"><slot /></template>
            <span
                v-if="icon"
                :class="iconClasses">
                <component
                    :is="icon"
                    v-if="icon"
                    :class="{
                        'w-3': xSmall,
                        'w-5': !xSmall && !small,
                        'w-4': small
                    }"
                />
                <span v-if="slot.icon">
                    <slot name="icon" />
                </span>
            </span>
        </span>
    </component>
</template>
