<script setup lang="ts">
import Wii from '@/Components/Icon/Wii.vue'
import WiiU from '@/Components/Icon/WiiU.vue'
import Xbox from '@/Components/Icon/Xbox.vue'
import Meta from '@/Components/Icon/Meta.vue'
import Linux from '@/Components/Icon/Linux.vue'
import Apple from '@/Components/Icon/Apple.vue'
import Steam from '@/Components/Icon/Steam.vue'
import Switch from '@/Components/Icon/Switch.vue'
import Amazon from '@/Components/Icon/Amazon.vue'
import Windows from '@/Components/Icon/Windows.vue'
import Android from '@/Components/Icon/Android.vue'
import OculusRift from '@/Components/Icon/OculusRift.vue'
import Playstation from '@/Components/Icon/Playstation.vue'
import { computed, type PropType } from 'vue'
import type { Game } from '@/Types/Models/Game'

const props = defineProps({
    game: Object as PropType<Game>,
    limit: {
        type: Number,
        default: 3
    }
})

const iconMap = {
    win: Windows,
    winphone: Windows,
    ps: Playstation,
    ps2: Playstation,
    ps3: Playstation,
    ps5: Playstation,
    psvr: Playstation,
    psvr2: Playstation,
    'ps4--1': Playstation,
    wiiu: WiiU,
    wii: Wii,
    'series-x-s': Xbox,
    xbox: Xbox,
    xbox360: Xbox,
    xboxone: Xbox,
    switch: Switch,
    linux: Linux,
    mac: Apple,
    ios: Apple,
    appleii: Apple,
    android: Android,
    'steam-vr': Steam,
    'amazon-fire-tv': Amazon,

    'meta-quest-2': Meta,
    'meta-quest-3': Meta,

    'oculus-rift': OculusRift
}

const uniqueIcons = computed(() => {
    const icons = props.game.platforms.map(function (platform) {
        return iconMap[platform.slug] ?? platform.logo
    })

    return [...new Set(icons)].splice(0, props.limit)
})

function getNameFromIcon (icon) {
    if (typeof icon === 'object') {
        return props.game.platforms.find(platform => iconMap[platform.slug] === icon).name
    }

    return props.game.platforms.find(platform => platform.logo === icon).name
}

const moreIconsCount = computed(() => {
    return props.game.platforms.length - uniqueIcons.value.length
})
</script>

<template>
    <ul class="flex items-center gap-3">
        <template
            v-for="icon in uniqueIcons"
            :key="icon">
            <li v-if="icon !== ''">
                <component
                    :is="icon"
                    v-if="typeof icon === 'object'"
                    v-tooltip="getNameFromIcon(icon)"
                    class="h-4" />
                <img
                    v-else-if="typeof icon === 'string' && icon"
                    v-tooltip="getNameFromIcon(icon)"
                    :alt="getNameFromIcon(icon)"
                    :src="icon"
                    class="w-3">
            </li>
        </template>

        <li
            v-if="moreIconsCount > 0"
            class="text-xs">
            + {{ moreIconsCount }}
        </li>
    </ul>
</template>
